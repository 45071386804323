<template>
  <div class="main-page trace-data">
    <custom-header></custom-header>
    <div class="desc">
      <h4>溯源数据展示</h4>
    </div>
    <div class="inner">
      <van-tabs v-model="activeTab" @change="changeTab" class="custom-headers-com" swipeable>
        <van-tab name="1">
          <template #title>
            <div class="tab-headers">软件</div>
          </template>
          <ul class="block-list">
            <li class="item" v-for="(item, index) in appList" :key="index" @click="viewDetail(item, 'app', 1)">
              <div class="box" :style="{'background-image': `url(${item.appimg})`}"></div>
              <p>{{item.name}}</p>
            </li>
          </ul>
        </van-tab>
        <van-tab name="2">
          <template #title>
            <div class="tab-headers">算法</div>
          </template>
          <ul class="block-list">
            <li class="item" v-for="(item, index) in algorithmList" :key="index" @click="viewDetail(item, 'algorithm', 2)">
              <div class="box">
                <div class="font">{{item.appname}}</div>
              </div>
            </li>
          </ul>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import CustomHeader from "@/components/CustomHeader.vue";

import { DATA } from "@/remote";
export default {
  name: "trace-data",
  components: {
    "custom-header": CustomHeader,
  },
  data(){
    return {
      appList: [],
      algorithmList: [],
      activeTab: "1",
    };
  },
  mounted() {
    this.getTraceSourceAppDemo();
    this.getTraceSourceAlgorithmDemo();

    let tabKey = localStorage.getItem('rj_tab_key');
    if (tabKey) {
      this.activeTab = tabKey;
      localStorage.removeItem('rj_tab_key')
    }
  },
  methods: {
    // 切换tab
    changeTab(value) {
      this.activeTab = value;
    },

    getTraceSourceAppDemo() {
      DATA.getTraceSourceAppDemo().then(res => {
        if (res.code == 200) {
          this.appList = (res.data || []).map(item => ({
            ...item,
            name: (item.desc || '').split('商业换脸软件:')[1],
          }));
        }
      })
    },

    getTraceSourceAlgorithmDemo() {
      DATA.getTraceSourceAlgorithmDemo().then(res => {
        if (res.code == 200) {
          this.algorithmList = res.data || [];
        }
      })
    },

    viewDetail(item, type, tab) {
      localStorage.setItem('rj_trace_detail_data', JSON.stringify({item, type, tab}));
      setTimeout(() => {
        this.$router.push({
          name: 'TraceDataDetail'
        })
      }, 10)
    }
  }
};
</script>

<style lang="less">
@import '../../assets/less/const';
  .trace-data {
    .inner {
      margin-bottom: -32px;
      .second-title {
        margin-top: 16px;
      }
      .block-list {
        margin-left: -16px;
        margin-right: -16px;
        > a {
          display: inline-block;
        }
        .item {
          display: inline-block;
          margin: 0 16px 32px 15.5px;
          text-align: center;
          font-size: 30px;
          color: #00F1FF;
          font-weight: bold;
          .box {
            width: 226px;
            height: 226px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            position: relative;
            .gradient-border();
          }
          > p {
            margin-top: 18px;
            overflow: hidden;
            width: 226px;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .font {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 0 10px;
            width: 100%;
            word-break: break-all;
          }
        }
      }
    }
  }
</style>
